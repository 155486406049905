<template>
  <span v-html="selfServiceTerminalAddress" />
</template>

<script setup lang="ts">
import type { Terminal } from '~types/addressStore'

import type { GUID } from '@arora/common'

const { terminalId } = defineProps<{ terminalId: GUID }>()

const addressStore = useAddressStore()

const selfServiceTerminalAddress = computed<string>(() => {
  const terminalsData: Terminal[] = addressStore.Terminals?.data ?? []

  return terminalsData.find((t) => t.ID === terminalId)?.Address ?? ''
})

onMounted(async () => {
  await addressStore.initTerminals()
})
</script>
